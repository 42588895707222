<template>
  <div class="connectUs">
    <header class="head"></header>
    <div class="nav-area">
      <b-container class="nav-container container">
        <b-row>
          <b-col class="breadcrumb_box">
            <span class="icon-icon_home iconfont"></span>
            <b-breadcrumb separator-class="el-icon-arrow-right">
              <b-breadcrumb-item to="/">
                <span>首页</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <section class="partner">
      <div class="title"><span class="bd"></span>联系我们</div>
      <div class="main">
        <div class="main-top">
          <div v-for="item in connectUsList" :key="item.name"  class="item">
            <img :src="require(`../assets/connectUs/icon_${item.index}.png`)" alt="">
            <span class="item-name" >{{item.name}}</span>
            <div style="height: 10px;border-top: 1px solid #bcbcbc;width: 10%;"></div>
            <span class="item-text" >{{item.text}}</span>
          </div>
        </div>
        <div class="map" style="position: relative;height: 700px">
          <div style="position: absolute;width: 100%;padding: 0 20px">
            <img  class="img-map">
            <div class="start-search start-search-hover">
              <img class="img-location" style="width: 60px" src="../assets/connectUs/location.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {navItemList, concatUsInfoList,connectUsList} from "@/baseData";
export default {
  computed: {
    url() {
      return url
    }
  },
  data() {
    return {
      navItemList,
      concatUsInfoList,
      connectUsList,
      pathCrumb: [],
      scale: 0.9,
      boxHeight: undefined,
    };
  },
  created() {
    // 获取面包屑导航
    this.navItemList.forEach((element) => {
      if (element.to == this.$store.state.currentPath) {
        this.pathCrumb = [element];
      }
    });
  },

  mounted() {
    // Todo 优化
    setTimeout(() => {
      let boxHeight = document.getElementsByClassName("box")[0].offsetHeight;
      let boxWidth = document.getElementsByClassName("box")[0].offsetWidth;
      if (boxWidth <= 900) {
        this.scale = (boxHeight * 0.9) / 593 - 0.2;
      } else {
        this.scale = (boxHeight * 0.9) / 593;
      }
    }, 0);
const that = this;
    window.onresize = () => {
      that.$emit('blankHeight', window.innerWidth > 992 ?  412 : 0);
      return (() => {
        let boxHeight = document.getElementsByClassName("box")[0].offsetHeight;
        let boxWidth = document.getElementsByClassName("box")[0].offsetWidth;
        if (boxWidth <= 900) {
          this.scale = (boxHeight * 0.9) / 593 - 0.2;
        } else {
          this.scale = (boxHeight * 0.9) / 593;
        }
      })();
    };
  }
};
</script>

<style lang="less" scoped>
.connectUs {
  //background-color: #ffffff;
  background-color: #fafbfc;
  .head {
    height: 320px;
    background-image: url("../assets/connectUs/banner_ustext.png"), url("../assets/connectUs/banner_us.jpg");
    background-repeat: no-repeat;
    background-position: 70% 60%, center;
    background-size: 180px, cover;
  }
  .nav-area {
    width: 100%;
    height: 58px;
    color: #666666;
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    position: sticky;
    top: 82px;
    background: #fff;
    z-index: 11;
    .container {
      max-width: 1420px !important;
      padding-left: 0;
      padding-right: 0;
    }
    .nav-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .row {
      margin: 0;
    }
    .breadcrumb_box {
      display: flex;
      align-items: center;
      height: 58px;
      .breadcrumb-item {
        align-items: center;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "";
        width: 0;
        height: 0;
        border-width: 4px;
        border-style: solid;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: #6c757d;
        border-right: 0;
      }
    }
    ol {
      font-size: 1.5rem;
      background-color: #fff;
      margin-top: 1rem;
      a {
        color: #666666;
        span + span {
          margin-left: 6px;
        }
      }
    }

    .tabs_box {
      height: 58px;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: -2px 0 0;
        padding: 0;
        height: 100%;
        li {
          & + li {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
          }
          height: 100%;
          display: flex;
          align-items: center;
          list-style: none;
          font-size: 1.5rem;
          border-bottom: 2px solid transparent;
          margin-bottom: -1px;
          cursor: pointer;
          &.active {
            color: #015aab;
            border-bottom: 2px solid #015aab;
          }
        }
      }
    }
  }
}

.partner {
  max-width: 1400px;
  margin: 0 auto;
  .main {
    padding-bottom: 50px;
    overflow: hidden;
    position: relative;
    .main-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      max-width: 1400px;
      padding: 5px;
      gap: 1%;
      .item{
        background-color: white;
        width: 28%;
        min-width: 200px;
        padding: 10px;
        margin: 10px 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        box-shadow: 0 0 10px #eee;
        .item-name{
          font-size: 2rem;
        }
        .item-text{
          font-size: 1.5rem;
          word-wrap:break-word;
        }
      }
    }

  }

  .title {
    margin: 35px auto;
    font-size: 3rem;
    color: #333333;
    line-height: 32px;
    display: flex;
    span {
      width: 4px;
      height: 28px;
      background: #015aab;
      display: inline-block;
      margin-right: 10px;
      margin-top: 2px;
    }
  }
}

.map{
  margin-top: 30px;
  .img-map{
    //width: 100%;
    content: url("../assets/connectUs/map.png");
  }
}

.start-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40px;
  height: 40px;
  padding: 20px;
  left:710px;
  top: -380px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  //background-color: rgb(87, 169, 190);
  border-radius: 50%;
  box-shadow:
      0 0 0 12px rgba(87, 169, 190, 0.2),
      0 0 0 24px rgba(87, 169, 190, 0.1),
      0 0 0 36px rgba(87, 169, 190, 0);
  .img-location{
    position: absolute;
    width: 40px;
    content:url("../assets/connectUs/location.png");
  }
}

.start-search-hover {
  animation: ripple 0.8s linear infinite;
}

@media screen and (max-width: 900px) {
  .map{
    .img-map{
      content: url("../assets/connectUs/xs-map.jpg");
    }
  }
 .start-search{
   left: 360px;
   top: -330px;
   .img-location{
     content: url("../assets/connectUs/location.png");
   }
 }
  .partner {
    .main {
      .main-top {
        .item {
          background-color: white;
          width: 40%;
          min-width: 240px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .partner {
    .main {
      .main-top {
        .item {
          background-color: white;
          width: 40%;
          min-width: 180px;
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .head {
    background-image: url("../assets/connectUs/banner_ustext.png"), url("../assets/connectUs/banner_us_440.png") !important;
  }
  .partner {
    .main {
      .main-top {
        .item {
          background-color: white;
          width: 40%;
          min-width: 130px;
          .item-name{
            font-size: 1.5rem;
          }
          .item-text{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .nav-area{
    display: none;
  }
   .breadcrumb_box {
    display: none !important;
  }
}

@keyframes ripple {
  0% {
    box-shadow:
        0 0 0 0 rgba(87, 169, 190, 0.47),
        0 0 0 12px rgba(87, 169, 190, 0.2),
        0 0 0 24px rgba(87, 169, 190, 0.1);
  }
  100% {
    box-shadow:
        0 0 0 12px rgba(87, 169, 190, 0.2),
        0 0 0 24px rgba(87, 169, 190, 0.1),
        0 0 0 36px rgba(87, 169, 190, 0);
  }
}

</style>
