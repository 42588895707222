<template>
  <section id="company">
    <div class="banner" :style="{ height: height, backgroundSize: bgSize }"></div>

    <div class="tab">
      <b-container>
        <b-row>
          <b-col class="breadcrumb_box">
            <span class="icon-icon_home iconfont"></span>
            <b-breadcrumb separator-class="el-icon-arrow-right">
              <b-breadcrumb-item to="/">
                <span>首页</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>
          <b-col class="tabs_box">
            <ul>
              <li
                v-for="(item, index) in tabsList"
                :key="index"
                :class="{
                  active: index == tabIndex,
                }"
                @click="goAnchor(index)"
              >
                {{ item }}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="tabPan">
      <b-container id="nav_1">
        <b-row class="row_1">
          <b-col md="12" lg="6" xl="6" class="left_box">
            <div class="left_img_box">
              <img class="women" alt=""/>
            </div>
          </b-col>
          <b-col md="12" lg="6" xl="6" class="right_box">
            <div class="title"><span class="bd"></span>关于我们</div>
            <div class="article">
              <article>
                长春市把手科技有限公司成立于2015年，是东三省最早从事法律大数据开发与应用的科技企业之一。我司于2017年获得科大讯飞技术入股，2018年被评为国家级高新技术企业和吉林省科技型中小企业，2022年“吉企查-企业合规法律风险自检平台”入选2022政法智能化建设智慧创新产品，同年，被中国科学技术协会评为“科创中国”新锐企业，2023年获得全国商业科技进步奖一等奖。
              </article>
              <article>
                公司以“推开法律职业变革之门”为使命，自主研发了“把手案例”（www.lawsdata.com）司法文书和法律法规数据库、智能法律咨询机器人和企业法律风险自检系统等核心法律产品。在数据处理、司法行政辅助应用等方向拥有50余项软件著作权和3项发明专利。
              </article> <article>
              把手科技依托吉林大学法学院、吉林大学司法数据应用研究中心，形成产学研一体化的结构，是吉林大学法律硕士培养基地。通过法律与技术的深度融合，运用自研的自然语言处理、大数据分析和法律知识图谱等技术，致力于开发辅助公、检、法、司等政法工作人员和律师、企业法务等法律从业者的智慧法务产品。
              </article>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="general" style="margin-bottom: 40px">
        <div class="message">
          <div class="message-item"  v-for="(item, index) in companyList" :key="index" style="">
            <p style="color: white;">
              <countTo style="font-size: 4.5rem" :ref="'countTo' + index" :startVal="startVal" :endVal="item.text" :duration="3000"></countTo>
              <span style="font-size: 2rem" class="suffix">{{ item.suffix }}</span>
            </p>
            <p style="color: white;font-size: 1.3rem">
              <span class="postfix">{{ item.postfix }}</span>
            </p>
          </div>
        </div>
      </b-container>

      <div class="map_box">
        <b-container>
          <b-row class="row_3">
            <b-col xl="5" md="6" cols="12" class="article_col">
              <article>
                近年来，随着公司业务的发展壮大，已成为了一家以“把手案例”(www.lawsdata.com)为基础，同时为政府、政法机关、企业提供专业法律信息化、智能化产品和数据咨询服务的综合业务创新型公司。公司服务网络遍布祖国大江南北，除吉林省外，覆盖北京、山东、山西、安徽、广东、新疆等多个省市。服务对象包括国家部委、地方政府、地方政法委、法院、检察院、公安机关、大型企业、律师事务所等。并组织和参与了2项国家重点课题，多项省、市重点课题项目。
              </article>
            </b-col>
            <b-col xl="6" md="6" cols="12" offset-xl="1" class="scale">
              <img src="../assets/company/company-map.png" />
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-container id="nav_2" >
        <b-row class="row_4">
          <b-col>
            <div class="title"><span class="bd"></span>新闻资讯</div>
            <el-carousel arrow="never" :loop="true" :autoplay="true" indicator-position="outside" v-if="showNews">
              <el-carousel-item v-for="(itm, idx) in Math.ceil(companyImg.length / each1)" :key="idx">
                <div class="partner_wrap">
                  <div class="partner_item" style="" v-for="(item, index) in companyImg.slice(idx * each1, (idx + 1) * each1)" :key="index + 'x'">
                    <img :src="require('../assets/company/s' + (idx * each1 + index + 1) + '.png')" />
                    <div class="description" :title="item" style="padding: 8px;">
                      <div style="border: 2px solid #015aab;width: 10%;height: 1px;margin-top: 20px"></div>
                      <div style="word-wrap:break-word;" v-html="item">{{item}}</div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
import { navItemList, companyList, companyImg } from "@/baseData";
import countTo from "vue-count-to";
export default {
  components: { countTo },
  data() {
    return {
      navItemList,
      companyList,
      companyImg,
      activeName: "first",
      navHeight: 0,
      tabsList: ["关于我们", "新闻资讯"],
      tabIndex: 0,
      imgList: [],
      startVal: 0,
      bgSize: "190px,cover",
      each1: 4,
      showNews :true,
      currenEach1:4,
    };
  },
  created() {
    this.navItemList.forEach((element) => {
      if (element.to == this.$store.state.currentPath) {
        this.pathCrumb = [element];
      }
    });
    this.checkEach1SizeAndCalcH()
  },
  mounted() {
    // 动态配置el-tabs的高度
    this.importImg();
    const  that = this
    window.onresize = () => {
      that.$emit('blankHeight', window.innerWidth > 992 ?  412 : 0);
      this.checkEach1SizeAndCalcH()
    };
  },
  methods: {
    switchTabs(index) {
      this.tabIndex = index;
    },
    checkEach1SizeAndCalcH(){
      let that = this
      const  windowInnerWidth = window.innerWidth
      if (windowInnerWidth > 440 && windowInnerWidth <= 992 ) {
        that.each1 = 3;
        that.calcH();
      }
      if(windowInnerWidth <=440 ){
        that.each1 = 2;
        that.calcH();
      }
      if(windowInnerWidth>992 ){
        that.each1 = 4;
        that.calcH();
      }
    },
    // 动态设置banner图高度
    calcH() {
      let calcHeight = Math.floor((document.body.clientWidth * 875) / 1920) - 10;
      this.height = `${calcHeight}px`; //动态计算轮播图的高度;
      if (calcHeight < 200) {
        this.bgSize = "120px,cover";
      }
    },

    goAnchor(index) {
      this.tabIndex = index;
      let selector = `#nav_${index + 1}`;
      let anchor = document.querySelector(selector); // 参数为要跳转到的元素id
      window.scrollTo({
        top: anchor.offsetTop - (index == 0 ? 98 : 57),
        behavior: "smooth",
      });
    },

    // 动态写入长度
    importImg() {
      for (let i = 1; i <= companyImg.length; i++) {
        this.imgList.push({
          src: require("../assets/company/s" + i + ".png"),
          text: companyImg[i - 1].text,
        });
      }
      this.imgList = [...this.imgList, ...this.imgList, ...this.imgList];
    },
  },
};
</script>

<style lang="less" scoped>
#company {
  //background: #fff;
  background: #fafbfc;
  .banner {
    position: relative;
    max-height: 320px;
    background-image: url("../assets/company/company_jieshaotext.png"), url("../assets/company/company-jieshao.jpg");
    background-repeat: no-repeat;
    background-position: 30% 60%, center;
    background-size: 190px, cover;
    .banner-text {
      position: absolute;
      left: 0;
      right: 0;
      top: 24rem;
      // padding: 0 30px;
      img {
        width: 19rem;
      }
    }
  }

  .tab {
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    position: sticky;
    top: 82px;
    background: #fff;
    z-index: 11;
    .breadcrumb_box {
      display: flex;
      align-items: center;
      color: #666666;
      .breadcrumb-item {
        align-items: center;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "";
        width: 0;
        height: 0;
        border-width: 4px;
        border-style: solid;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: #6c757d;
        border-right: 0;
      }
      ol {
        font-size: 1.5rem;
        background-color: #fff;
        margin-top: 1rem;
        a {
          color: #666666;
          span + span {
            margin-left: 6px;
          }
        }
      }
    }
    .tabs_box {
      height: 58px;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        height: 100%;
        li {
          height: 100%;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          display: flex;
          align-items: center;
          list-style: none;
          font-size: 1.5rem;
          border-bottom: 2px solid transparent;
          margin-bottom: -1px;
          &.active {
            color: #015aab;
            border-bottom: 2px solid #015aab;
          }
        }
      }
    }
  }

  .tabPan {
    margin-top: 4rem;
    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    // row-1
    .row_1 {
      margin-bottom: 6rem;
      .left_box {
        display: flex;
        align-items: center;
        justify-content: center;
        .left_img_box {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 100%;
          .women{
            width: 80%;
            content: url("../assets/company/company-women.png");
          }
          > img {
            width: 80%;
          }
        }
      }
      .right_box {
        font-size: 1.5rem;
        padding-left: 5rem;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 3rem;
          color: #333333;
          line-height: 32px;
          display: flex;
          margin-bottom: 3rem;
          span {
            width: 4px;
            height: 28px;
            background: #015aab;
            display: inline-block;
            margin-right: 10px;
            margin-top: 2px;
          }
        }
        .article {
          text-indent: 2em;
          line-height: 2;
          color: #666;
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-evenly;
        }
      }
    }
    // row-2
    .row_2 {
      color: #fff;
      background: url("../assets/company/blue.png") no-repeat center/cover;
      height: 20rem;
      margin: 0 0 6rem;
      .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          font-size: 4.4rem;
          font-weight: 400;
          margin: 0;
          line-height: 2.6rem;
          text-align: center;
          &:first-child {
            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 1px;
              height: 32px;
              background: rgba(255, 255, 255, 0.2);
            }
          }
          &:last-child {
            line-height: 1.8rem;
          }
        }
        .suffix {
          font-size: 0.4em;
        }
        .postfix {
          font-size: 0.3em;
        }
      }
    }
    // row-3
    .map_box {
      // background-color: #f4f7fa;
      background: #f4f7fa url("../assets/company/bg.png") no-repeat center / cover;
      padding: 6rem 0;
      .article_col {
        display: flex;
        align-items: center;
        line-height: 2;
        font-size: 1.5rem;
        word-break: break-all;
        color: #666;
        text-indent: 2em;
      }
      img {
        width: 100%;
      }
    }
    .row_4 {
      margin-top: 6rem;
      margin-bottom: 6rem;
      .title {
        font-size: 3rem;
        color: #333333;
        line-height: 32px;
        display: flex;
        margin-bottom: 3.6rem;
        span {
          width: 4px;
          height: 28px;
          background: #015aab;
          display: inline-block;
          margin-right: 10px;
          margin-top: 2px;
        }
      }
      /deep/ .el-carousel__container {
        height: 460px;
      }
      .partner_wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.5rem;
        color: #666;
        .partner_item {
          display: flex;
          flex-direction: column;
          width: 23%;
          margin: 1%;
          img {
            width: 100%;
            box-shadow: 0 0 10px #eee;
          }
          .description {
            flex: 1;
            margin: 0;
            border-bottom: 1px solid #eeeeee;
            box-shadow: 0 0 10px #eee;
            line-height: 2;
          }
        }
      }
    }
  }
  .message{
    width: 100%;
    background: url('../assets/company/blue.png') ;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    //justify-content: flex-start;
      .message-item{
        padding: 30px 0;
        display:flex;justify-content:center;text-align:center;width:20%;flex-direction: column;
        min-width: 180px;
        min-height: 200px;
    }
  }
}
@media screen and (min-width: 1368px) {
  .container {
    max-width: 1368px !important;
  }

}
@media screen and (max-width: 1368px) {
  #company {
    .banner {
      .banner-text {
        top: 16rem;
      }
    }
    .tabPan .row_4 /deep/ .el-carousel__container {
      height: 470px;
    }
    .partner_wrap{
      .partner_item{
        width: 23%;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  #company {
    .row_2 {
      .col {
        p {
          font-size: 3rem !important;
        }
      }
    }
  }

}
@media screen and (max-width: 992px) {
  .tabPan .row_4 /deep/ .el-carousel__container {
    height: 400px !important;
  }

  .left_box {
    display: flex;
    align-items: center;
    justify-content: center;
    .left_img_box {
      display: flex;
      cursor: pointer;
      justify-content: space-evenly;
      width: 100%;
      //background-color: red;
      .women{
        width: 80%;
        content: url("../assets/company/company-women-row.png") !important;
      }
    }
  }
  .row_1 .right_box {
    padding-left: 15px !important;
    margin-top: 3rem;
  }
  #company .tabPan .row_4 {
    /deep/ .el-carousel__container {
      height: 370px !important;
    }
    .title {
      margin-bottom: 2rem;
    }
    .partner_wrap {
      .partner_item {
        width: 31%;
        margin: 1%;
      }
    }
  }
}



@media screen and (max-width: 768px) {
  .tabPan .row_4 /deep/ .el-carousel__container {
    height: 460px !important;
  }
  #company .tabPan {
    .map_box {
      background: #f4f7fa !important;
      .article_col {
        margin-bottom: 3rem;
      }
    }
  }
  #company .tabPan .row_4 {
    /deep/ .el-carousel__container {
      height: 330px !important;
    }
    .title {
      margin-bottom: 2rem;
    }
    .partner_wrap {
      .partner_item {
        width: 31%;
        font-size: 1.2rem;
        //margin: 1%;
      }
    }
  }

}

@media screen and (max-width: 573px) {
  #company .tabPan .row_4 {
    /deep/ .el-carousel__container {
      height: 450px !important;
    }
  }

}

@media screen and (max-width: 440px) {
  #company .tabPan .general {
    padding: 0;
  }
  #company .tabPan .row_4 {
    /deep/ .el-carousel__container {
      height: 400px !important;
    }
    .title {
      margin-bottom: 2rem;
    }
    .partner_wrap {
      .partner_item {
        width: 48%;
        margin: 1%;
      }
    }
  }
  section .banner .banner-text img {
    width: 15rem !important;
  }
  section .breadcrumb_box {
    display: none !important;
  }
  section .tabs_box ul {
    justify-content: flex-start !important;
  }
  #company .tabPan .row_1,
  #company .tabPan .row_4 {
    margin-bottom: 2rem;
    .right_box {
      font-size: 1.4rem;
      .title {
        font-size: 2.4rem;
        margin-bottom: 2rem;
      }
    }
  }
  #company .tabPan .row_2 {
    margin-bottom: 0;
  }
  #company .tabPan .row_4 {
    margin-top: 2rem;
  }
  #company .tabPan {
    .map_box {
      padding: 2rem 0;
    }
    .row_1 .left_box .left_img_box {
      flex-direction: column;
      > img {
        width: 100%;
      }
      .left_img_box_mini {
        width: 100%;
        flex-direction: row;
        margin: 5px 0;
        justify-content: space-evenly;
        img {
          width: 49%;
          + img {
            margin-left: 2%;
          }
        }
      }
    }
  }
}
</style>
