<template>
  <div class="cooperation">
    <header class="head"></header>

    <div class="nav-area">
      <b-container class="nav-container">
        <b-row>
          <b-col class="breadcrumb_box">
            <span class="icon-icon_home iconfont"></span>
            <b-breadcrumb separator-class="el-icon-arrow-right">
              <b-breadcrumb-item to="/">
                <span>首页</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>

          <b-col class="tabs_box">
            <ul>
              <li
                v-for="(item, index) in tabsList"
                :key="index"
                :class="{
                  active: index == tabIndex,
                }"
                @click="goAnchor(index)"
              >
                {{ item }}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <section class="partner" id="nav_1">
      <div class="title"><span class="bd"></span>合作企业</div>
      <div class="main">
        <div class="main-top" style=" background-color: #fafbfc;">
          <div v-for="(item,index) in cooperationList.banner" :class="`item item${index}`" :id="`item${index}`" :key="index"  >
            <div :id="`img${index}`" style="cursor: pointer;margin-bottom: 20px;" @click="cooClickBanner(index)" >
             <img class="img-bg" :src="require(`../assets/cooperation/coo-${index+1}.png`)" style="box-shadow: 0 0 10px #eee">
             <img style="width: 100%" v-show="currentIndex === index" :src="require(`../assets/cooperation/coo-bottom.png`) ">
            </div>
            <div class="cont" :id="`cont${index}`" v-show="currentIndex ===index">
              <el-icon class="el-icon-close" style="float: right;cursor: pointer;font-size: 2rem" @click="cooCloseCont(index)"></el-icon>
              <div style="font-size: 2.2rem;color: #1870ba;font-weight: 600;">{{item.title}}</div>
              <div v-for="(contItem,index) in item.text" :key="index">
                <article >{{contItem}}</article>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="partner" id="nav_2">
      <div class="title"><span class="bd"></span>服务对象</div>
      <div class="main">
        <img class="shangwuhezuo" alt=""/>
      </div>
    </section>
  </div>
</template>

<script>
import { cooperationList, cooperationPartner } from "@/baseData";

export default {
  data() {
    return {
      tabIndex: 0,
      tabsList: ["合作企业", "服务对象"],
      carouselIndex: 0,
      cooperationList,
      cooperationPartner: [],
      pageNo: 1,
      pageSize: 12,
      total: 0,
      currentIndex:-1,
      contHeight:0,
    };
  },

  created() {
    this.getCooperationPartner();
  },
  mounted() {
    const that = this
    window.onresize = function () {
      that.$emit('blankHeight', window.innerWidth > 992 ?  412 : 0);
          if(that.currentIndex!==-1){
              let imgHeight =document.getElementById('img'+ that.currentIndex).offsetHeight
              let contHeight =document.getElementById('cont'+ that.currentIndex).offsetHeight
            document.getElementById('item'+ that.currentIndex).style.height =( imgHeight + contHeight + 40) + 'px'
          }
    };
  },

  methods: {
    goAnchor(index) {
      this.tabIndex = index;
      let selector = `#nav_${index + 1}`;
      let anchor = document.querySelector(selector); // 参数为要跳转到的元素id
      window.scrollTo({
        top: anchor.offsetTop - (index === 0 ? 68 : 67),
        behavior: "smooth",
      });
    },

    cooClickBanner(index){
      if(this.currentIndex === index){
        return
      }
      if(this.currentIndex!==-1){
        let imgtHeight =document.getElementById('img'+ this.currentIndex).offsetHeight
        document.getElementById('item'+ this.currentIndex).style.height =imgtHeight  + 'px'
      }
      this.currentIndex = index
      this.$nextTick(()=>{
        let imgHeight =document.getElementById('img'+ index).offsetHeight
        let contHeight =document.getElementById('cont'+ index).offsetHeight
        document.getElementById('item'+ index).style.height =( imgHeight + contHeight + 50) + 'px'
      })
    },

    cooCloseCont(index){
      this.currentIndex = -1
      let imgtHeight =document.getElementById('img'+ index).offsetHeight
      document.getElementById('item'+ index).style.height =imgtHeight  + 'px'
    },


    handleCarouselChange(index) {
      this.carouselIndex = index;
    },

    handleMouseOut() {
      let index = this.carouselIndex > 2 ? 0 : this.carouselIndex + 1;
      this.$refs.carousel.setActiveItem(index);
    },

    handleToggle(index) {
      this.$refs.carousel.setActiveItem(index);
    },

    getCooperationPartner() {
      const start = (this.pageNo - 1) * this.pageSize;
      this.cooperationPartner = cooperationPartner.slice(
        start,
        start + this.pageSize
      );
      this.total = cooperationPartner.length;
    },

    changePage(pageNo) {
      this.pageNo = pageNo;
      this.getCooperationPartner();
    },
  },
};
</script>

<style lang="less" scoped>
.cooperation {
  //background-color: #ffffff;
  background-color: #fafbfc;
  .head {
    height: 320px;
    background-image: url("../assets/cooperation/banner_shangwutext.png"), url("../assets/cooperation/banner_shangwu.jpg");
    background-repeat: no-repeat;
    background-position: 20% 60%, center;
    background-size: 400px, cover;
  }
  .nav-area {
    width: 100%;
    height: 58px;
    color: #666666;
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    position: sticky;
    top: 82px;
    background: #fff;
    z-index: 11;
    .container {
      max-width: 1420px !important;
      padding-left: 0;
      padding-right: 0;
    }
    .nav-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .row {
        margin: 0;
      }
      .breadcrumb_box {
        display: flex;
        align-items: center;
        height: 58px;
        .breadcrumb-item {
          align-items: center;
        }
        .breadcrumb-item + .breadcrumb-item::before {
          display: inline-block;
          padding-right: 0.5rem;
          color: #6c757d;
          content: "";
          width: 0;
          height: 0;
          border-width: 4px;
          border-style: solid;
          border-bottom-color: transparent;
          border-top-color: transparent;
          border-left-color: #6c757d;
          border-right: 0;
        }
      }
      ol {
        font-size: 1.5rem;
        background-color: #fff;
        margin-top: 1rem;
        a {
          color: #666666;
          span + span {
            margin-left: 6px;
          }
        }
      }

      .tabs_box {
        height: 58px;
        ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: -2px 0 0;
          padding: 0;
          height: 100%;
          li {
            & + li {
              margin-left: 1.5rem;
              margin-right: 1.5rem;
            }
            height: 100%;
            display: flex;
            align-items: center;
            list-style: none;
            font-size: 1.5rem;
            border-bottom: 2px solid transparent;
            margin-bottom: -1px;
            cursor: pointer;
            &.active {
              color: #015aab;
              border-bottom: 2px solid #015aab;
            }
          }
        }
      }
    }
  }
  .title {
    margin: 35px auto;
    font-size: 3rem;
    color: #333333;
    line-height: 32px;
    display: flex;
    span {
      width: 4px;
      height: 28px;
      background: #015aab;
      display: inline-block;
      margin-right: 10px;
      margin-top: 2px;
    }
  }
  .banner {
    max-width: 1400px;
    height: 500px;
    margin: 0 auto;
    text-align: center;
    transition: all 0.5s ease;
    .carousel-main {
      display: flex;
      justify-content: center;
      max-width: 1400px;
      margin: 0 auto;
      font-size: 15px;
      }
    }
    .toggle {
      display: flex;
      justify-content: center;
      gap: 100px;
      margin-top: 20px;
      &-item {
        cursor: pointer;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        padding: 5px 0;
        &.active {
          box-shadow: 1px 1px 5px #b7b7b7;
        }
      }
    }
    .right-icon-sd,
    .right-icon-0,
    .right-icon-1 {
      //width: 130px;
      height: 70px;
      display: block;
    }
    .right-icon-sd {
      background-color: #7db3d9;
      color: #ffffff;
      font-size: 17px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
    }
    .right-icon-0 {
      background: url("../assets/cooperation/banner-item-1.png") no-repeat
        center center / 100% auto;
      background-position: center 1px;
    }
    .right-icon-1 {
      background: url("../assets/cooperation/banner-item-2.png") no-repeat
        center center / 100% auto;
    }
  }
  .partner {
    max-width: 1400px;
    margin: 0 auto;
    .main {
      padding-bottom: 50px;
      overflow: hidden;
      position: relative;
      .main-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 1400px;
        padding: 3px;
        gap: 1%;
        .item {
          width: 24.2%;
          height: auto;
          //box-shadow: 0 0 5px #e5e1e1;
          .img-bg{
            width: 100%;
          }
          .img-bottom{
            opacity: 0;
            height: 15px;
            //width: calc(100% - 26px);
            //margin-left: 13px;
            //margin-top: -40px;
          }

          .cont {
            width: calc(100% - 6px);
            position: absolute;
            left:1px;
            padding: 20px 30px 30px 30px;
            box-shadow: 0 1px 3px 1px #0000003d;
            font-size: 1.5rem;
            font-weight: 400;
            color: #555555;
            line-height: 3.6rem;
            text-indent: 2em;
            text-align: justify;
          }
        }
        .item{
          .img-bottom{
            width: 100%;
            opacity: 100%;
            height: 15px;
          }
        }
      }
      .el-pagination {
        margin-top: 55px;
        margin-right: 7px;
        text-align: right;
      }
      .shangwuhezuo{
        width: 100%;
        content:url("../assets/cooperation/coo-hezuo-1400.jpg");
      }
    }
  }
@media screen and (max-width: 800px) {
  .cooperation {
    .head {
      height: 400px !important;
    }
    .banner {
      .carousel-main {
        .left {
          display: none;
        }
      }
      .toggle {
        gap: 0;
      }
    }
    .partner {
      .main {
        .main-top {
          gap: 10px;
          .item {
            width: 45%;
          }
        }
        .shangwuhezuo{
          width: 100%;
          content:url("../assets/cooperation/coo-hezuo-600.jpg");
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .partner {
    .main {
      .main-top {
        gap: 10px;
        .item {
          width: 32%;
        }
      }
      .shangwuhezuo{
        width: 100%;
        content:url("../assets/cooperation/coo-hezuo-800.jpg");
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .cooperation{
    .nav-area{
      .nav-container{
        .breadcrumb_box {
          display: none !important;
        }
        .tabs_box ul {
          justify-content: flex-start !important;
        }
      }
    }
  }

}
</style>
