<template>
    <section id="productDetail">
        <div class="banner">
            <b-container class="banner-text">
                <img src="../assets/product/banner-icon.png" alt="" />
            </b-container>
        </div>
        <b-container class="container detail-content">
            <b-row>
                <b-col class="breadcrumb_box">
                    <span class="icon-icon_home iconfont"></span>
                    <b-breadcrumb separator-class="el-icon-arrow-right">
                        <b-breadcrumb-item to="/">
                            <span>首页</span>
                        </b-breadcrumb-item>
                        <b-breadcrumb-item
                            v-for="(item, index) in pathCrumb"
                            :key="index"
                            :to="item.to"
                            >{{ item.name }}</b-breadcrumb-item
                        >
                    </b-breadcrumb>
                </b-col>
            </b-row>
            <div class="card">
                <div class="title">
                    {{ detail.title }}
                </div>
                <p
                    v-for="(item, index) in detail.text"
                    :key="index"
                    v-html="item"
                ></p>

                <!-- <a :href="detail.href" target="_blank"
                    >产品链接<img
                        src="../assets/productDetail/link-icon.png"
                        width="14"
                /></a> -->
            </div>
            <div class="card">
                <el-row :gutter="24" class="init_row">
                    <el-col
                        :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="12"
                        v-for="(item, index) in detail.imgList"
                        :key="index"
                        class="init_col"
                        :class="
                            detail.arrange[index] == 3
                                ? 'init_3'
                                : detail.arrange[index] == 2
                                ? 'init_2'
                                : 'init_1'
                        "
                    >
                        <img :src="item" class="img" max-width="100%" />
                        <!-- <img
                            src="../assets/productDetail/bd.png"
                            class="bd"
                            alt=""
                        /> -->
                    </el-col>
                </el-row>
            </div>
        </b-container>
    </section>
</template>

<script>
import { navItemList, productDetailList } from "@/baseData/index";
export default {
    data() {
        return {
            navItemList,
            pathCrumb: [],
            activeName: 'first',
            productDetailList,
            detail: {}
        }
    },
    created() {
        this.navItemList.forEach(element => {
            if (element.to == this.$store.state.currentPath) {
                this.pathCrumb = [element]
            }
            if (element.hasOwnProperty('children') && element.children.length > 0) {
                element.children.forEach(el => {
                    if (el.to.split('?')[0] == this.$store.state.currentPath) {
                        el.name = this.$route.query.name;
                        el.to = `${this.$store.state.currentPath}?name=${this.$route.query.name}`;
                        // this.pathCrumb = [element, el];
                        this.pathCrumb = [element];
                    }
                })
            }
        });
    },
    mounted() {
        this.productDetailList.forEach(element => {
            if (element.title == this.$route.query.name) {
                this.detail = element
            }
        });
      const that = this
      window.onresize = function () {
        that.$emit('blankHeight', window.innerWidth > 992 ?  412 : 0);
      };
    },
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
    .init_row {
        display: block !important;
    }
}
.init_row {
    display: flex;
    flex-wrap: wrap;
}
.init_col {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    img {
        max-width: 100%;
        min-width: 50%;
    }
}
.init_3 {
    flex: 1 1 33%;
    justify-content: center;
}
.init_2 {
    flex: 1 1 50%;
}
.init_1 {
    flex: 1 1 100%;
}
#productDetail {
    background: #f4f7fa;
    overflow: auto;
}
@media screen and (min-width: 1368px) {
    .banner {
        height: 30rem;
        .banner-text {
            top: 90px;
        }
    }
    .container {
        max-width: 1368px !important;
        padding-left: 0;
        padding-right: 0;
        // overflow: hidden;
    }
}
section {
    background: #f0f2f5;
    .banner {
        position: relative;
        height: 39rem;
        background: url("../assets/product/banner.png") no-repeat center/cover;
        .banner-text {
            position: absolute;
            left: 0;
            right: 0;
            top: 90px;
            // padding: 0 30px;
            img {
                width: 19rem;
            }
        }
    }
    .card {
        width: 100%;
        background: #fff;
        border: none;
        padding: 80px 95px;
        margin-bottom: 20px;
        .title {
            font-size: 3.6rem;
            color: #333333;
            margin-bottom: 50px;
        }
        p {
            font-size: 1.5rem;
            color: #666666;
            line-height: 30px;
            margin-bottom: 30px;
            /deep/span {
                font-weight: bold;
            }
        }
        a {
            width: 154px;
            height: 40px;
            background: rgba(22, 120, 255, 0);
            border: 1px solid #015aab;
            border-radius: 20px;
            font-size: 1.6rem;
            color: #015aab;
            display: flex;
            padding: 0 32px;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            img {
                width: 14px;
                height: 14px;
            }
            &:hover {
                text-decoration: none;
            }
        }
        .img {
            position: relative;
            z-index: 99;
        }
        .bd {
            margin-top: -46px;
            width: 100%;
        }
    }
    .nav-area {
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #e3e3e3;
        color: #666666;
    }
    .detail-content {
        margin-top: -24rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        .row {
            margin: 0;
        }
        .breadcrumb_box {
            display: flex;
            align-items: center;
            margin-top: 48px;
            margin-bottom: 10px;
            color: #fff;
            .breadcrumb-item {
                align-items: center;
            }
            .breadcrumb-item + .breadcrumb-item::before {
                display: inline-block;
                padding-right: 0.5rem;
                content: "";
                width: 0;
                height: 0;
                border-width: 4px;
                border-style: solid;
                border-bottom-color: transparent;
                border-top-color: transparent;
                border-left-color: #fff;
                border-right: 0;
                margin-top: 7px;
            }
            ol {
                font-size: 1.5rem;
                background-color: transparent;
                color: #fff;
                margin-bottom: 0;
                a {
                    color: #fff;
                    span + span {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1368px) {
    .banner {
        height: 30rem;
        .banner-text {
            top: 90px;
        }
    }
    .container {
        max-width: 1368px !important;
        padding-left: 0;
        padding-right: 0;
        // overflow: hidden;
    }
}
@media screen and (max-width: 425px) {
    section .card .title {
        font-size: 3rem;
    }
    section .banner .banner-text img{
        width: 15rem;
    }
}
@media screen and (min-width: 320px) and(max-width: 767px) {
    section {
        .card {
            padding: 35px 40px;
            .title {
                margin-bottom: 30px;
            }
            p {
                margin-bottom: 15px;
            }
        }
    }
}
</style>
