import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentPath: '',//当前路径
    miniFooter: false,//是否是mini size footer
    switchIndex: 0,//nav的index
  },
  mutations: {
    setMiniFooter(state, payload) {
      state.miniFooter = payload.miniFooter
    },
    switchNav(state, payload) {
      state.currentPath = payload.routeObj.path;//记录当前路径
      document.title = '把手科技-' + payload.routeObj.meta.ch;
      let searchPath = payload.routeObj.meta.p || payload.routeObj.name;//根据name确定switchIndex
      state.switchIndex = router.options.routes.findIndex(item => {
        return item.name == searchPath;
      });
      //之前的逻辑 四个nav index==3 最后一个 footer缩小 没有问题说明
      // this.commit('setMiniFooter', { miniFooter: state.switchIndex == 3 })
      //新版本全部都展示所有的footer
      this.commit('setMiniFooter', { miniFooter: false })
    }
  },
  actions: {
  },
  modules: {
  }
})
