<template>
  <footer id="Footer">
    <b-container id="Footer-main" fluid="sm" v-if="!$store.state.miniFooter">
      <b-row v-if="!isMiniScreen">
        <b-col>
          <div
            class="nav-item"
            v-for="(item, index) in navItemList"
            :key="index"
          >
            <b-link :to="item.to">{{ item.name }}</b-link>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="8" lg="6" cols="12" v-if="!isMiniScreen">
          <div
            class="foot-info"
            v-for="(item, index) in footInfoList"
            :key="'f' + index"
          >
            <span :class="item.icon" class="iconfont"></span>
            <span>{{ item.content }}</span>
            <span>{{ item.message }}</span>
          </div>
        </b-col>
        <b-col xl="4" lg="6" cols="12" class="img-wrap">
          <div class="img-outer">
            <p>把手案例小程序</p>
            <img src="../assets/footer/miniprogram.png" />
          </div>
          <div class="img-outer">
            <p>法律数据实验室</p>
            <img src="../assets/footer/laboratory.png" />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="cutting-line"></div>
    <b-container id="Footer-sub" fluid="sm">
      <b-row class="sub-row">
        <b-col class="sub-left" lg="6" md="8" cols="12">
          <img src="../assets/footer/logo-g.png" />
          <i class="verticalLine"></i>
          <div>
            <p>©2015-2020 把手科技 .All Rights Reserved</p>
            <p>
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >吉ICP备15006659号-3</a
              >
            </p>
          </div>
        </b-col>
        <b-col class="sub-right" lg="6" md="4" cols="0">
          <b-form-select
            class="select"
            v-model="selected"
            :options="options"
            placeholder="友情链接"
            @change="changeLink"
          ></b-form-select>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import { navItemList, footInfoList } from "@/baseData/index";
import * as Server from "@/api/server";
import { apiList } from "@/api/apiList.js";
export default {
  data() {
    return {
      navItemList,
      footInfoList,
      path: "",
      selected: null,
      options: [{ value: null, text: "友情链接" }],
      isMiniScreen: false,
    };
  },
  created() {
    let that = this;
    that.changeScreenWidth();
    window.onresize = function () {
      that.changeScreenWidth();
    };
  },
  methods: {
    changeScreenWidth() {
      this.isMiniScreen =
        document.body.clientWidth <= 440 ||
        document.documentElement.clientWidth <= 440;
      this.isMiniScreen =false
    },
    changeLink(e) {
      if (e == null) return;
      window.open(e, "_blank");
      this.selected = null;
    },
  },
  mounted() {
    //获取链接
    Server.Axios({
      method: "POST",
      url: apiList.getLink + "?linkExchange=WEBSITE_INDEX",
      loading: false,
    }).then((dataSource) => {
      dataSource.data.data.forEach((element) => {
        let json = {
          value: element.url,
          text: element.name,
        };
        this.options.push(json);
      });
    });
  },
};
</script>

<style lang="less" scoped>
#Footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background: url("../assets/footer/footer.png") center center/cover no-repeat;
  &.noFix {
    // position: relative;
  }
  #Footer-main {
    padding-top: 4rem;
    padding-bottom: 3.5rem;
    font-size: 1.6rem;
    .nav-item {
      font-weight: 400;
      color: #ffffff;
      line-height: 8.4rem;
      opacity: 0.6;
      display: inline-block;
      a {
        color: inherit;
      }
      &::after {
        content: "·";
        display: inline-block;
        position: relative;
        margin: 0 1.5rem;
      }
    }
    .nav-item:last-child::after {
      content: " ";
    }
    .foot-info {
      color: #ffffff;
      line-height: 4rem;
      opacity: 0.4;
      .iconfont {
        margin-right: 10px;
      }
    }
    .img-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      .img-outer img {
        width: 100%;
        // height: 13.6rem;
        padding: 6px;
        background: linear-gradient(to left, #ffffff66, #ffffff66) left top
            no-repeat,
          linear-gradient(to bottom, #ffffff66, #ffffff66) left top no-repeat,
          linear-gradient(to left, #ffffff66, #ffffff66) right top no-repeat,
          linear-gradient(to bottom, #ffffff66, #ffffff66) right top no-repeat,
          linear-gradient(to left, #ffffff66, #ffffff66) left bottom no-repeat,
          linear-gradient(to bottom, #ffffff66, #ffffff66) left bottom no-repeat,
          linear-gradient(to left, #ffffff66, #ffffff66) right bottom no-repeat,
          linear-gradient(to left, #ffffff66, #ffffff66) right bottom no-repeat;
        background-size: 1px 20px, 20px 1px, 1px 20px, 20px 1px;
      }
      .img-outer + .img-outer {
        margin-left: 6rem;
      }
    }
  }
  .cutting-line {
    border-bottom: 1px solid #ffffff19;
  }
  .verticalLine {
    width: 1px;
    height: 30px;
    background-color: #ffffff19;
    margin-left: 10px;
    margin-right: 10px;
  }
  #Footer-sub {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.41);
    //height: 82px;
    height: 100px;
    .sub-row {
      .sub-left {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }
        a {
          color: rgba(255, 255, 255, 0.41);
        }
      }
      .sub-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        overflow: hidden;
        .select {
          font-size: 1.4rem !important;
          width: 180px;
          height: 30px;
          border-color: #323c47;
          outline: none;
          color: rgba(255, 255, 255, 0.4);
          border-radius: 50px;
          padding-left: 2rem;
          background: #323c47 url("../assets/footer/arrow-down.png") no-repeat
            right 0.75rem center/8px 4px;
        }
      }
    }
  }
  @media screen and (min-width: 1368px) {
    #Footer-main,
    #Footer-sub {
      max-width: 1368px !important;
      // overflow: hidden;
    }
  }
  @media screen and (max-width: 992px) {
    position: inherit;
    .img-wrap {
      justify-content: flex-start !important;
      margin-top: 3rem;
    }
    .sub-right {
      margin-top: 20px;
      // text-align: start !important;
    }
  }
  @media screen and (max-width: 768px) {
    #Footer-main .nav-item::after {
      margin: 0 0.5rem;
    }
    #Footer-sub {
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      font-size: 1.2rem;
      height: 120px;
      .sub-row {
        height: 100%;
        .sub-left {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          i{
            display: none !important;
          }
        }
        .sub-right {
          justify-content: center;
        }
      }
      p {
        display: inline;
      }
    }

  }
  @media screen and (max-width: 440px) {
    #Footer-main {
      .nav-item{
        font-size: 1.4rem;
      }
      padding-top: 3rem;
      padding-bottom: 3rem;
      .img-wrap {
        justify-content: space-evenly !important;
        margin-top: 0;
        .img-outer {
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.8);
          width: 120px;
          & + .img-outer {
            margin-left: 0;
          }
        }
      }
    }

    #Footer-sub {
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      font-size: 1.2rem;
      height: 120px;
      .sub-row {
        height: 100%;
        .sub-left {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          i{
            display: none !important;
          }
        }
        .sub-right {
          //display: none !important;
          justify-content: center;
        }
        //i {
        //  display: none;
        //}
      }
      p {
        display: inline;
      }
    }

    //#Footer-sub {
    //  padding-top: 1rem;
    //  padding-bottom: 1rem;
    //  text-align: center;
    //  font-size: 1.2rem;
    //
    //  .sub-row {
    //    height: 100%;
    //    .sub-left {
    //      display: flex;
    //      flex-direction: column;
    //      justify-content: space-evenly;
    //    }
    //    .sub-right {
    //      display: none !important;
    //    }
    //    i {
    //      display: none;
    //    }
    //  }
    //  p {
    //    display: inline;
    //  }
    //}
  }
}
.hidden {
  display: none;
}
</style>
